@font-face {
  font-family: 'Noto Sans';
  src: url('../public/fonts/NotoSans-Regular.eot');
  src: url('../public/fonts/NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/NotoSans-Regular.woff2') format('woff2'),
  url('../public/fonts/NotoSans-Regular.woff') format('woff'),
  url('../public/fonts/NotoSans-Regular.ttf') format('truetype'),
  url('../public/fonts/NotoSans-Regular.svg#NotoSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../public/fonts/NotoSans-Medium.eot');
  src: url('../public/fonts/NotoSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/NotoSans-Medium.woff2') format('woff2'),
  url('../public/fonts/NotoSans-Medium.woff') format('woff'),
  url('../public/fonts/NotoSans-Medium.ttf') format('truetype'),
  url('../public/fonts/NotoSans-Medium.svg#NotoSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('../public/fonts/NotoSans-SemiBold.eot');
  src: url('../public/fonts/NotoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/NotoSans-SemiBold.woff2') format('woff2'),
  url('../public/fonts/NotoSans-SemiBold.woff') format('woff'),
  url('../public/fonts/NotoSans-SemiBold.ttf') format('truetype'),
  url('../public/fonts/NotoSans-SemiBold.svg#NotoSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


:root {
  --main-bg-color: #000A2B;
  --head-bg-color: #002553;
  --orange-bg-color: #FF8A53;
  --primary-text-color: #CDBEB4;
  --error-text-color: #C24046;
  --main-text-color: #FFF;
  --input-bg-color: #FFF;
}


body {
  background-color: var(--main-bg-color);
  font-family:'Noto Sans',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}



#root {
  background-color: var(--main-bg-color);
  font-family:'Noto Sans',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}


.titleGraphs {
  font-size: 15px;
  color:var(--primary-text-color);
  font-weight: 600;
  letter-spacing: 0.15em;
}
 .titlePage{
  font-size: 32px;
   color:var(--primary-text-color);
   font-weight: 400;
   letter-spacing: 2.175px;
}


.button-colored {
  background-color: var(--orange-bg-color);
}

.MuiPaper-root {
  background-color: var(--main-bg-color);
}


.divider {
  display: inline;
  margin-left: 5px;
  margin-right: 5px;
  height: 10px;
  width: 1px;
}

.clickable {
  cursor: pointer;
}


.right {
  text-align: right;
}

.left {
  text-align: left;
}

#email-login {
  padding-left:8px;
  font-size: 16px;
}
#-password-login{
  padding-left:8px;
  font-size: 16px;
}


.MuiTextField-root {
  border-bottom: 1px solid #AEA49B;
}

.MuiInput-input{
  padding-bottom: 2px;
  color: var(--main-text-color);
  font-weight: 400;
  font-size: 12px;
  font-family: "Noto Sans",sans-serif;
}

.MuiInput-root:before{
  border-bottom: none;
  content: none;
  transition: none;
  -webkit-transition: none;
  -webkit-appearance: none;
}
.MuiInput-root:after{
  border-bottom: none;
  content: none;
  transition: none;
  -webkit-transition: none;
  -webkit-appearance: none;
}
.MuiAutocomplete-popupIndicator {
  transform: none;
}
/*THIS FOR ARROWS AT AUTOCOMPLETE MUI*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.lastTd {
   border-left: 1px solid var(--main-bg-color);
}

.orangeButton {
  background-color: var(--orange-bg-color);
  font-weight: bold;
  font-size: 110%;
}

.textLabel {
  color: var(--main-text-color);
}
.iconLabel{
  color: var(--main-text-color);
}
.iconLabel:hover{
color:#42a5f5
}
.iconLabel:focus-visible{
  color:#42a5f5
}

.MuiAutocomplete-endAdornment {
  color: #FFFFFF;
}


.MuiPaper-root{
  color:var(--main-text-color)
}
.MuiPickersDay-root{
  color:var(--main-text-color);
  background-color: var(--main-bg-color);
}

.MuiButtonBase-root{
  background: var(--main-bg-color);
}
.menuButton  {
  background: var(--head-bg-color);
  margin-left: 10px;
}
 .MuiButtonBase-root-MuiPickersDay-root.Mui-selected{
   background: var(--head-bg-color);
 }
 .Mui-selected{
  background: var(--head-bg-color);
}


.MuiInputBase-formControl{
  /*background:var(--main-bg-color);*/
  color: #CDBEB4;
  font-size: 12px;
  font-family: Noto Sans;
  padding-bottom: 5px;
  /*height: 22px;*/
}
.MuiInputBase-input{
  padding-left: 0px;
}

.MuiFilledInput-root:after{
  border-bottom: none;
}

.MuiIconButton-root {
  color: #CDBEB4;
  font-size:14px;
}
.MuiSlider-root{
  color:var(--orange-bg-color);
  margin-bottom: 0;
}
.MuiSlider-rail{
  color:var(--orange-bg-color);
  background:var(--orange-bg-color);
  height:3px;
}
.MuiSlider-track{
 height: 4px;
color:var(--orange-bg-color);
}
.MuiSlider-thumb{
  height: 20px;
  width: 20px;
  background:var(--orange-bg-color);
  color:var(--orange-bg-color);
  border:none;
  box-shadow: none;
}

.MuiSlider-thumb.MuiSlider-thumbColorPrimary{
  border:none;
  box-shadow:none;
}


.MuiSlider-valueLabel{
  font-size: 12px;
  font-weight: 400;
  background:unset;
  color:var(--orange-bg-color);
 }

.MuiSlider-mark{
  background:var(--orange-bg-color);
  border: none;
  height: 3px;
}
.MuiSlider-markActive{
  background:var(--orange-bg-color);
  height: 3px;
}
.MuiSlider-markLabel{
  color: #CDBEB4;
}



.myAutocomplete .MuiSvgIcon-root{
  color:#FFFFFF !important;
}

#background-video {
  height: 100%;
  width: 100%;
  float: left;
  top: 0;
  padding: 0;
  position: fixed;
}



.RemoveDialog {
  color: #FFFFFF !important;
  font-weight: bold;
  border-left: 1px solid red;
  border-left: 1px solid red;
}

.preventSelect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.widget-stack {
  width: 100%;
  padding: 5px;
  background-color: #FF8A53;
}

.rectangle {
    width:100%;
    height:60px;
    background:#00f;
    display: inline;
    position:fixed;
    top:0;
    left:0;
}

.nowrap {
  white-space: nowrap;
}

.coloredLine {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
  font-size: 1.5px;
  vertical-align: middle;
  display: inline;
}
.advancedBtn{
  cursor: pointer;
  height: 24px;
  background: var(--orange-bg-color);
  /*background: var(--main-bg-color);*/
  color: #000A2B;
  /*color:  var(--primary-text-color);*/
  border: 1px solid  var(--orange-bg-color);
  /*border: 1px solid  var(--primary-text-color);*/
  border-radius: 1px;
  font-size: 12px;
}
.disabledBtn{
  cursor: pointer;
  height: 24px;
  background: var(--main-bg-color);
  color:  var(--primary-text-color);
  border: 1px solid  var(--primary-text-color);
  border-radius: 1px;
  font-size: 12px;
}

/* prevent-select */
body {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.loader{
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 68px;
  height: 68px;
  color: #fff;
}
.loader:before,
.loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: var(--orange-bg-color);
  transform: rotateY(70deg);
  animation-delay: .4s;
}
@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

.errorMessage{
  width: 400px;
  height: 150px;
  font-size: 14px;
  margin-top: 20px;
  padding: 16px 22px ;
  align-items: center;
  text-align: center;
  color:var(--error-text-color);

}

.errorText {
  font-size: 10px;
  color:var(--error-text-color);
}



.warrningMessage{
color: var(--orange-bg-color);
  width: 645px;
  border: 1px solid var(--orange-bg-color);
  border-radius: 5px;
  padding: 10px 15px;
}

@media only screen and (max-width:950px) {
  #root {
    background-color: var(--main-bg-color);
    font-family:'Noto Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
  }
  body {
    font-size: 8px;
  }
  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
  .titleGraphs {
    font-size: 10px;
  }
  .titlePage{
    font-size: 18px;
  }
  .advancedBtn {
    font-size: 8px;
    height: 18px;
  }
  .MuiInputBase-formControl {
    font-size: 8px;
  }
  .MuiInputBase-input {
    font-size: 8px;
  }
  .errorText{
    font-size: 7px;
  }
  .MuiIconButton-root{
   font-size: 10px;
  }
  .MuiSlider-markLabel{
    font-size: 8px;
    padding-right: 20px;
  }
  .MuiSlider-valueLabelLabel{
    font-size: 8px;
  }
  .MuiSlider-thumb{
    width:15px;
    height: 15px;
  }
}

