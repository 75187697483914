
.assetPie td{
    text-overflow: ellipsis;
    color: var(--main-text-color);
    background-color: var(--head-bg-color);
    border-bottom:1px solid  var(--main-bg-color);
    padding-left: 8px;
    font-size: 12px;
    font-weight: 500;
    padding-right: 8px;
    text-align: left;
    height: 28px;
}
.assetPie {
    border-collapse: collapse;
}
.assetPie th{
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    color:var(--main-text-color);
    padding-left: 8px;

}
.assetPie td:first-child {
    width: 577px;
    text-align: left;
}
.assetPie td:nth-child(2) {
    width: 121px;
}


.keymetrics {
    border-collapse: collapse;
}
.keymetrics td {
    height: 36px;
    color: #AEA49B;
    border-bottom: 1px solid #AEA49B;
    border-top: 1px solid #AEA49B;
    text-align: center;
}

.keymetrics th {
    height: 36px;
    color: #AEA49B;
    text-align: center;
}

.keymetrics td:first-child {
    width: 40%;
    border-right: 1px solid #AEA49B;
    text-align: left;
    padding-left: 8px;
}
.keymetrics td:nth-child(2) {
    width: 30%;
    border-right: 1px solid #AEA49B;
}

.keymetricsSimulation {
    border-collapse: collapse;
}
.keymetricsSimulation td {
    height: 36px;
    color: #AEA49B;
    border-bottom: 1px solid #AEA49B;
    border-top: 1px solid #AEA49B;
    text-align: center;
}

.keymetricsSimulation th {
    height: 36px;
    color: #AEA49B;
    text-align: center;
}

.keymetricsSimulation td:first-child {
    width: 40%;
    border-right: 1px solid #AEA49B;
    text-align: left;
    padding-left: 8px;
}
.keymetricsSimulation td:nth-child(2) {
    width: 20%;
    border-right: 1px solid #AEA49B;
}
.keymetricsSimulation td:nth-child(3) {
    width: 20%;
    border-right: 1px solid #AEA49B;
}
.keymetricsSimulation td:nth-child(3) {
    width: 20%;
}


.performancemetrics{
    border-collapse: collapse;
}
.performancemetrics td{
    height: 36px;
    color: #AEA49B;
    border-bottom: 1px solid #AEA49B;
    border-top: 1px solid #AEA49B;
    text-align: center;
}

.performancemetrics th{
    height: 36px;
    color: #AEA49B;
    text-align: center;
}
.performancemetrics td:first-child{
    width: 40%;
    border-right: 1px solid #AEA49B;
    text-align: left;
    padding-left: 8px;
}

.performancemetrics td:nth-child(2) {
    width: 30%;
    border-right: 1px solid #AEA49B;
}

.btnDownload{
    background-color:var(--error-text-color);
}

.tdportfolioTable{
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    text-align: left;
    color: var(--main-text-color);
    background: var(--main-bg-color);
    font-weight: 500;
    font-size: 12px;
    border-bottom: 1px solid var(--main-text-color);
    height: 28px;
}

.composition {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    text-align: left;
    color: var(--main-text-color);
    background: var(--head-bg-color);
    font-weight: 500;
    font-size: 12px;
    border-bottom: 1px solid var(--main-bg-color);
    height: 28px;
}
.compositionSimulation {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    text-align: center;
    color: var(--main-text-color);
    background: var(--head-bg-color);
    font-weight: 500;
    font-size: 12px;
    border-bottom: 1px solid var(--main-bg-color);
    height: 28px;
}

.MuiTextField-root.pickerOverview{
    width: 235px;
}
.MuiTextField-root.pickerSimulation{
    width: 292px;
}
.MuiTextField-root.pickerOptimization{
    width: 235px;
}
.MuiTextField-root.createPortfolioPicker{
    width: 400px;
}
.MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
}

.worstDrawdown{
    border-collapse: collapse;
}

.worstDrawdown td {
    height: 28px;
    color: #FFFFFF;
    border-bottom: 1px solid #AEA49B;
    border-top: 1px solid #AEA49B;
    text-align: center;
    font-size: 12px;
    font-weight: 500;

}

.worstDrawdown th {
    height: 28px;
    color: #AEA49B;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
}

.worstDrawdown td:first-child {
    width: 30%;
    border-right: 1px solid #AEA49B;
}
.worstDrawdown td:nth-child(2) {
    width: 30%;
    border-right: 1px solid #AEA49B;
}
.worstDrawdown td:nth-child(3) {
    width: 30%;
    border-right: 1px solid #AEA49B;
}
.arrow_box{
    background: var(--main-bg-color);
    opacity: 0.9;
    font-size: 12px;
    font-family: "Noto Sans";
    padding-left: 4px;
    padding-right: 4px;
    box-shadow: none !important;
}

.removeIcon{
    font-size: 14px;
    color: var(--main-text-color);
    margin-left: 3px;
    margin-top: 4px;
}
.removeIcon:hover{
    font-size: 16px;
    color:var(--main-text-color);
    margin-top: 3px;
    margin-left: 2px;
}

#chart.apexcharts-tooltip{
    box-shadow: none !important;
}
.apexcharts-tooltip{
    border: 1px solid #FF8A53 !important;
    box-shadow: none !important;
}


.value-tooltip .arrow_box .apexcharts-tooltip{
    margin-right: 10px !important;
}

#apexchartsworldMarket .apexcharts-legend-series:nth-child(2) {
    /*margin-right: 220px !important;*/
    /*Summer Time*/
    margin-right:  160px !important;

}
#apexchartsworldMarket .apexcharts-legend-series:nth-child(4) {
    /*margin-right: 145px !important;*/
    /*Summer Time*/
    margin-right: 205px !important;
}
#apexchartsworldMarket .apexcharts-legend-series:nth-child(5) {
    margin-right: 230px !important;
}
 .puls-dot {

     stroke: rgba(255, 255, 255, 0);
     stroke-width: 0;
     animation-name: pulse-animation;
     animation-duration: 2s ;
     animation-iteration-count: infinite;
}

 .allocationField.MuiTextField-root{
     border-bottom: none;
 }

@keyframes pulse-animation {
    0% {
        stroke:none;
        stroke-width: 0;
    }
    100% {
        stroke: rgba(255, 255, 255, 0.5);
        stroke-width: 2px;
    }
}

.apexcharts-yaxistooltip-text{
 display: none;
}

.apexcharts-yaxistooltip{
    display: none;
}

.dataQualityGaps {
    border-collapse: collapse;
}
.dataQualityGaps td {
    height: 24px;
    color: #AEA49B;
    border-bottom: 1px solid #AEA49B;
    border-top: 1px solid #AEA49B;
    text-align: center;
}

.dataQualityGaps th {
    height: 24px;
    color: #AEA49B;
    text-align: center;
}

.dataQualityGaps td:first-child {
    width: 25%;
    border-right: 1px solid #AEA49B;
}
.dataQualityGaps td:nth-child(2) {
    width: 25%;
    border-right: 1px solid #AEA49B;
}
.dataQualityGaps td:nth-child(3) {
    width: 30%;
    border-right: 1px solid #AEA49B;
}

@media only screen and (max-width: 950px){
    #apexchartsworldMarket .apexcharts-legend-series:nth-child(2) {
        margin-right: 80px !important;
    }
    #apexchartsworldMarket .apexcharts-legend-series:nth-child(4) {
        margin-right: 85px !important;
    }
    #apexchartsworldMarket .apexcharts-legend-series:nth-child(5) {
        margin-right: 110px !important;
    }
    .tdportfolioTable {
        font-size: 8px;
    }
    .assetPie td {
        font-size: 8px;
    }
    .assetPie th {
        font-size: 8px;
    }
    .composition {
        font-size: 8px;
    }
    .compositionSimulation {
        font-size: 8px;
    }
    .removeIcon{
        font-size: 10px;
    }

    .MuiTextField-root.pickerOverview{
        width: 220px;
        font-size: 8px;
    }
    .MuiTextField-root.pickerSimulation{
        width: 220px;
    }
    .MuiTextField-root.pickerOptimization{
        width: 220px;
    }
    .worstDrawdown td{
        font-size: 8px;
    }
    .worstDrawdown th{
        font-size: 8px;
    }
}

