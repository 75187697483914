.hintBtn:hover{
    color:var(--orange-bg-color);
}
.assetTable span {
    text-overflow: ellipsis;
    overflow: hidden; line-height:1.26;
    color: var(--main-text-color);
    background-color: var(--main-bg-color);
}

.assetTable td  {
    text-overflow: ellipsis;
    color: var(--main-text-color);
    background-color: var(--main-bg-color);
    border-bottom: 1px solid #AEA49B;
    padding-left: 8px;
    text-align: center;
    width: 80px;
    height: 40px;
    font-Weight: 400;
    font-size: 12px;
}

.assetTable th {
    padding-left:12px;
    padding-bottom: 8px;
    height: 36px;
    text-align: center;
    font-Weight: 500;
    font-size: 12px;
    line-height: 20px;
}


.assetTable {
    border-collapse: collapse;
    table-layout: fixed
}

.assetTableControl {
    border-collapse: collapse;
    table-layout: fixed;
    color: var(--main-text-color);
    background-color: var(--main-bg-color);
}


.assetTable th:first-child{
    text-align: left;
    padding-left: 4px;
}

.assetTable th:nth-child(2) {
    text-align: right;
    padding-left: 0px;

}
.assetTable th:nth-child(3) {
    text-align: center;
    padding-left: 16px;
}

.assetTable td:first-child {
    padding-left: 4px;
}

.assetTable td:nth-child(2) {
   text-align: right;
}

.assetTable td:nth-child(3) {
    text-align: center;
    padding-left: 14px;
    padding-right: 12px;
}

.assetTable tr:last-child>td {
    border-bottom: none;
}
.assetTable .tableArrowDown {
    padding-top: 10px;
}
.assetTable .tableArrowUp {
    padding-top: 8px;
}



.portfolioTable span {
    text-overflow: ellipsis;
    /*overflow: hidden; line-height:1.26;*/
    color: var(--main-text-color);
    background-color: var(--head-bg-color);
}

.portfolioTable td  {
    text-overflow: ellipsis;
    color: var(--main-text-color);
    background-color: var(--head-bg-color);
    border-bottom:1px solid  var(--main-bg-color);
    padding: 0px 8px 0px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    height: 54px;
}

.portfolioTable th  {
    padding-left: 16px;
    height: 28px;
    font-Weight: 500;
    font-size: 12px;
    line-height: 20px;
}

.portfolioTable {
    border-collapse: collapse;
    table-layout: fixed;
}
.portfolioTable td:first-child {
    /*width: 15px;*/
    text-align: center;
    padding-left: 8px;
    padding-top: 6px;
}
.portfolioTable td:nth-child(2) {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
}
.portfolioTable td:nth-child(9) {
    text-align: center;
    /*padding-top: 6px;*/
}

.portfolioTable td:nth-child(3) {
    text-align: center;
}
.portfolioTable td:nth-child(4) {
    text-align: center;

}
.portfolioTable th:nth-child(7) {
    /*padding-left: 23px;*/
    text-align: center;
}
.portfolioTable th:nth-child(8) {
    padding-left: 23px;
    text-align: center;
}
.portfolioTable th:nth-child(4){
    padding-left: 24px;
    text-align: center;
}
.portfolioTable th:nth-child(5){
    padding-left: 32px;
    /*text-align: center;*/
}
.portfolioTable th:nth-child(2){
    text-align: left;
    padding-left: 8px;
}
.portfolioTable th:nth-child(3){
    text-align: center;
    padding-left: 32px;
}
.portfolioTable th:first-child{
    text-align: left;
    padding-left: 20px;
}
.portfolioTable .tableArrowDown {
    padding-top: 7px;
}
.portfolioTable .tableArrowUp {
    padding-top: 7px;
}



.updateportfolio  span{
    text-overflow: ellipsis;
    overflow: hidden; line-height:1.26;
    color: var(--main-text-color);
    /*background-color: var(--head-bg-color);*/
}

.updateportfolio td{
    text-overflow: ellipsis;
    color: var(--main-text-color);
    background-color: var(--head-bg-color);
    border-bottom:1px solid  var(--main-bg-color);
    padding-left: 8px;
    font-size: 12px;
    font-weight: 500;
    padding-right: 8px;
    text-align: left;
    width: 31px;
    height: 28px;
}
.updateportfolio th{
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    color:var(--main-text-color);
    padding-left: 8px;
}

.updateportfolio td:first-child {
    width: 853px;
}
.updateportfolio td:nth-child(2) {
    width: 118px;
}
.updateportfolio td:nth-child(3) {
    text-align: center;
}
.updateportfolio td:nth-child(4) {
    text-align: center;
    padding-top: 4px;
}

.updateportfolio tr:last-child>td {
    border-bottom: none;
    background: var(--main-bg-color);
}

.newsTable span {
    text-overflow: ellipsis;
    overflow: hidden; line-height:1.26;
    color: var(--main-text-color);
    background-color: var(--main-bg-color);
}

.newsTable td  {
    text-overflow: ellipsis;
    color: var(--main-text-color);
    background-color: var(--main-bg-color);
    border-bottom: 1px solid #AEA49B;
    padding-left: 8px;
    /*padding-right: 16px;*/
    text-align: center;
    font-size:12px ;
    /*width: 80px;*/
    height: 36px;
}

.newsTable th  {
    padding-left: 8px;
    padding-right: 16px;
    height: 36px;
    font-Weight: 500;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
}
.newsTable td:nth-child(2) {
   text-align: left;
}
.newsTable td:first-child {
   text-align: left;
}
.newsTable td:nth-child(3) {
   text-align: left;
}
.newsTable tr:last-child>td{
    border-bottom: none;
}
.newsTable th:nth-child(2) {
    text-align: left;
}
.newsTable th:nth-child(3) {
    text-align: left;
}
.newsTable th:first-child {
    padding-left: 8px;
}

.newsTable {
    border-collapse: collapse;
    table-layout: fixed;
}

.assetTableAdvanced span {
    text-overflow: ellipsis;
    overflow: hidden; line-height:1.26;
    color: var(--main-text-color);
    background-color: var(--main-bg-color);
}

.assetTableAdvanced td  {
    text-overflow: ellipsis;
    color: var(--main-text-color);
    background-color: var(--main-bg-color);
    border-bottom: 1px solid #AEA49B;
    padding-left: 8px;
    text-align: center;
    width: 80px;
    height: 40px;
    font-Weight: 400;
    font-size: 12px;
}

.assetTableAdvanced th {
    /*padding-left:12px;*/
    padding-bottom: 8px;
    height: 36px;
    /*text-align: center;*/
    font-Weight: 500;
    font-size: 12px;
    line-height: 20px;
}


.assetTableAdvanced {
    border-collapse: collapse;
    table-layout: fixed
}

.assetTableAdvancedControl {
    border-collapse: collapse;
    table-layout: fixed;
    color: var(--main-text-color);
    background-color: var(--main-bg-color);
}


.assetTableAdvanced th:first-child{
    text-align: left;
    padding-left: 4px;
}

.assetTableAdvanced th:nth-child(2) {
    text-align: right;
    padding-left: 0px;

}
.assetTableAdvanced th:nth-child(3) {
    text-align: center;
    /*padding-left: 40px;*/
    padding-left: 70px;
}
.assetTableAdvanced th:nth-child(4) {
    text-align: center;
    padding-left: 20px;
}

 .assetTableAdvanced th:nth-child(5) {
    text-align: center;
    padding-left: 26px;
}
.assetTableAdvanced th:nth-child(6) {
    text-align: center;
    padding-left: 50px;
}
.assetTableAdvanced th:nth-child(7) {
    text-align: center;
    padding-left: 50px;
}

.assetTableAdvanced td:first-child {
    padding-left: 4px;
}

.assetTableAdvanced td:nth-child(2) {
    text-align: right;
}
.assetTableAdvanced td:nth-child(5) {
    padding-left: 52px;
}

.assetTableAdvanced td:nth-child(3) {
    text-align: center;
    padding-left: 14px;
    padding-right: 12px;
}

.assetTableAdvanced tr:last-child>td {
    border-bottom: none;
}
.assetTableAdvanced .tableArrowDown {
    padding-top: 10px;
}
.assetTableAdvanced .tableArrowUp {
    padding-top: 8px;
}

@media only screen and (max-width: 950px) {
    .assetTable td {
        font-size: 8px;
    }
    .assetTable th {
        font-size: 8px;
    }
    .portfolioTable td {
        font-size: 8px;
    }
    .portfolioTable td:first-child {
        padding: 0;
        text-align: center;
    }
    .portfolioTable td:nth-child(3) {
        padding: 0;
    }
    .portfolioTable td:nth-child(4) {
        padding: 0;
    }
    .portfolioTable th:nth-child(3) {
        padding: 0;
        /*overflow: auto;*/
    }
    .portfolioTable th:nth-child(4) {
        padding: 0;
        /*overflow: auto;*/
    }

    .portfolioTable td span{
        font-size: 8px;
    }
    .portfolioTable th {
        font-size: 8px;
    }
}

