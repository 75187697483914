.xxx {
    background-color: yellow;
}

.header-title {
    background-color: white;
    color: red;
    text-align: center;
    width: 100%;
}

.graph1 {
    color: white;
    background-color: darkred;
}
