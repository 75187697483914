
.impactBadge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1px 8px;
    gap: 10px;

    width: 40px;
    height: 20px;

    border-radius: 4px;

    flex: none;
    order: 0;
    flex-grow: 0;
}
